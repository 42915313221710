<template>
  <main class="rules">
    <div class="rules-header">
      <h4>{{ t('pages.rules.title') }}</h4>
      <is-majority-wrapper>
        <icon-btn
          :size="Size.M"
          @click="goBack"
        >
          <icon
            :type="IconType.Contour"
            :name="IconName.Crosses"
            :size="Size.Xs"
          />
        </icon-btn>
      </is-majority-wrapper>
    </div>

    <div class="title-wrapper">
      <h1 v-html="title" />
      <edit-modal
        page-name="rules"
        @update="fetchPageContent"
      />
    </div>

    <div v-html="content" />
  </main>
</template>

<script lang="ts" src="./Rules.ts"></script>
<style lang="scss" src="./rules.scss"></style>
